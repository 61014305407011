import Layout from "../components/Layout"
import { Link } from "gatsby"
import React from "react"
import Seo from "../components/seo"

const ExplorePage = () => {

  return (
    <Layout >
      <Seo title="Explore" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading md:text-center">Explore</h1>
        <div className="ele-style mt-8 max-w-prose mx-auto">
        <p>
          I know. I know. There's a lot of coming soon pages... sorry about that. Here's the model pages that are live (<Link to="/tc530">TC530</Link>, <Link to="/tv650">TV650</Link>, and <Link to="/tv750">TV750</Link>). It takes a pretty insane amount of work to fully research all the details and specs, get images, etc. I've been at it for years, before Tal's Guide existed. I'm working as fast as I can to get more Talman models posted. 
          Be sure to checkout the <Link to="/media">media section</Link>. I've got a few ads scanned, links to catalogs, and some Talman related videos! Also, don't miss the <Link to="/store">store</Link>, there's a few Talman guitars for sale!
        </p>

        <p>I've also got pages to contact me, if you <Link to="/help-find">need help finding a Talman</Link> or you want to <Link to="/contact">sell your Talman</Link>.</p>
        </div>
        
        <h3 className="text-xl text-center mt-20">more coming soon <span role="img" aria-label="winking face">😉</span></h3>
      </div>
    </Layout>
  )
}

export default ExplorePage